<template>
    <div>
        <b-row no-gutters>
            <b-col lg="2" md="3" sm="12" xs="12">
                <sider-menu-items :items="menuSiderItems.items"/>
            </b-col>
            <b-col lg="10" md="9" sm="12" xs="12">
                <router-view></router-view>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import SiderMenuItems from "@/components/general/elements/menus/SiderMenuItems";

export default {
    name: "TradeContent",
    components: {SiderMenuItems},
    props: {},
    data() {
        return {
            menuSiderItems: {
                // <-- user -->
                items: [
                    // <-- admin -->
                    {
                        type: 'text',
                        text: this.$t('trade.sider_menu.trade'),
                    },
                    {
                        type: 'item',
                        text: this.$t('trade.sider_menu.group'),
                        path: '/trade/groups',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('trade.sider_menu.type'),
                        path: '/trade/types',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('trade.sider_menu.product'),
                        path: '/trade/products',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('trade.sider_menu.article'),
                        path: '/trade/articles',
                        disabled: true,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('trade.sider_menu.warehouse'),
                        path: '/trade/warehouses',
                        disabled: true,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('trade.sider_menu.delivery_price'),
                        path: '/trade/delivery_prices',
                        disabled: true,
                        active: false,
                    },
                ]
            },
        }
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
